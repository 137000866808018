<template>
  <a-select
    v-model="inputValue"
    @change="handleChange"
    @dropdownVisibleChange="getAccountList()"
    @popupScroll="handlePopupScroll"
    v-bind="$attrs"
    v-on="$listeners"
    @search="getAccountList"
    :loading="loading"
  >
    <a-select-option
      v-for="item in accountList"
      :key="item.authorId"
      :value="hasName ? `${item.authorId},${item.nickname}` : item.authorId"
      >{{ item.nickname }}</a-select-option
    >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
  </a-select>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import debounce from 'lodash/debounce';

export default {
  props: {
    value: {
      type: [String, Array],
    },
    dealerIds: {
      type: Array,
      default: () => [],
    },
    allEmptySearch: {
      type: Boolean,
      default: false,
    },
    hasName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.getAccountList = debounce(this.getAccountList, 500);
    return {
      loading: false,
      inputValue: undefined,
      accountList: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      filter: '',
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    dealerIds: {
      handler() {
        this.pagination.page = 1;
        this.accountList = [];
        this.getAccountList();
      },
      deep: true,
    },
  },
  methods: {
    async getAccountList(filter) {
      const params = {
        platform: 5,
      };
      if (this.dealerIds && this.dealerIds.length) {
        params.memberIds = this.dealerIds.join(',');
      } else {
        this.accountList = [];
        return;
      }
      if (this.filter !== filter) {
        this.pagination.page = 1;
        this.filter = filter;
      }
      this.loading = true;
      try {
        const { code, data, message } = await api.getAccountList({
          ...params,
          ...this.pagination,
          nickname: filter,
        });
        if (code === 200) {
          this.pagination.total = data.total;
          if (this.pagination.page === 1) {
            this.accountList = data.list;
          } else {
            this.accountList = [...this.accountList, ...data.list];
          }
        } else {
          return this.$message.error(`${message}`);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message.error('操作失败');
      }
    },
    handleChange(val) {
      this.$emit('input', val);
      this.$emit('change', val); // 避免使用@change 父组件会触发两次
      this.$emit('onChange', val);
    },
    /* 下拉滚动 */
    async handlePopupScroll(e) {
      //已经有的下拉项目 大于等于后台返回的下拉项总长度,那么就返回不请求了
      if (this.loading || this.accountList.length >= this.pagination.total) return;
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      if (scrollHeight - scrollTop - clientHeight < 20) {
        // 到达了底部,请求数据
        this.pagination.page++; //分页器自增1
        this.getAccountList();
      }
    },
  },
  created() {
    this.inputValue = this.value;
    this.getAccountList();
  },
};
</script>

<style scoped lang="scss"></style>
